import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Typography } from '@mui/material';
import { TextInputFormField } from '../../../../Shared/TextInputFormField';
import CCDropdownForm from '../../../../Shared/CCDropdownForm';
import { ReactComponent as EditIcon } from '../../../../../images/icons/edit_icon.svg';
import NewModal from '../../../../Shared/NewModal';

const validationSchema = yup.object({
  note: yup.string().required('Note content is required'),
  category: yup.string().required('Note type is required'),
});

interface UpdateNoteFormProps {
  initialValues: {
    note: string;
    category: string;
  };
  onSubmit: (values: { note: string; category: string }) => void;
  noteTypeOptions: Array<{ value: string; label: string }>;
  onClose: () => void;
}

const UpdateNoteForm: React.FC<UpdateNoteFormProps> = ({
  initialValues,
  onSubmit,
  noteTypeOptions,
  onClose,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    validateOnMount={true}
  >
    {(formik) => (
      <NewModal
        showCloseX
        title="Note"
        onClose={onClose}
        maxWidth="sm"
        actions={{
          primaryAction: {
            text: 'Save',
            onClick: () => formik.handleSubmit(),
            disabled:
              !formik.values.note ||
              !formik.values.category ||
              formik.isSubmitting ||
              !formik.dirty,
          },
          secondaryAction: {
            text: 'Cancel',
            onClick: onClose,
          },
        }}
      >
        <Form>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EditIcon />
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '22px',
                lineHeight: '30px',
                letterSpacing: '0.36px',
              }}
            >
              Edit note
            </Typography>
            <Field
              name="category"
              component={CCDropdownForm}
              options={noteTypeOptions}
              fullWidth
            />
            <Field
              label=""
              name="note"
              placeholder="Type here to add a new note..."
              component={TextInputFormField}
              multiline
              rows={2}
              variant="standard"
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '8px',
                  border: '1px solid #D4D4D9',
                  '& textarea': {
                    '&::placeholder': {
                      color: '#9999A4',
                    },
                  },
                  '&::before': {
                    display: 'none',
                  },
                  '&::after': {
                    display: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '12px',
                  color: '#2A2A30',
                },
              }}
            />
          </Box>
        </Form>
      </NewModal>
    )}
  </Formik>
);

export default UpdateNoteForm;
