import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../../images/icons/delete_icon.svg';
import NewModal from '../../../../Shared/NewModal';
import { dimGrey } from '../../../../../mainTheme';

interface DeleteNoteConfirmationProps {
  onClose: () => void;
  onDelete: () => void;
}

const DeleteNoteConfirmation: React.FC<DeleteNoteConfirmationProps> = ({
  onClose,
  onDelete,
}) => {
  const [confirmationText, setConfirmationText] = useState('');

  const handleDelete = () => {
    if (confirmationText === 'delete') {
      onDelete();
    }
  };

  return (
    <NewModal
      maxWidth="sm"
      onClose={onClose}
      title="Note"
      showCloseX
      actions={{
        primaryAction: {
          text: 'Delete',
          onClick: handleDelete,
          disabled: confirmationText !== 'delete',
        },
        secondaryAction: {
          text: 'Cancel',
          onClick: onClose,
        },
      }}
    >
      <Box>
        <DeleteIcon />
        <Box display="flex" flexDirection="column" gap={1.5} mt={2}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '30px',
              letterSpacing: '0.36px',
            }}
          >
            Confirm deletion
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '22px',
              letterSpacing: '0.2px',
              color: dimGrey,
            }}
          >
            The note will be deleted permanently.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          py={4}
          gap={0.5}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '22px',
              letterSpacing: '0.2px',
              color: dimGrey,
              ml: 2,
            }}
          >
            Type the word &quot;delete&quot; to confirm deletion.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={confirmationText}
            placeholder='Type "delete"'
            onChange={(e) => setConfirmationText(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </Box>
      </Box>
    </NewModal>
  );
};

export default DeleteNoteConfirmation;
