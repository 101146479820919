import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../store';
import NewModal from '../../../../Shared/NewModal';
import { clearNoteActionStatus } from '../../../../../store/devices/actionCreators';
import { ReactComponent as UpdateSuccessIcon } from '../../../../../images/icons/note_success_icon.svg';
import { ReactComponent as UpdateFailedIcon } from '../../../../../images/icons/note_failed_icon.svg';
import { ReactComponent as DeleteSuccessIcon } from '../../../../../images/icons/delete_success_icon.svg';
import { ReactComponent as DeleteFailedIcon } from '../../../../../images/icons/delete_failed_icon.svg';

interface NoteActionFeedbackModelProps {
  onClose: () => void;
}

const NOTE_MESSAGES = {
  SECONDARY_MESSAGE: {
    UPDATE: {
      SUCCESS: 'You can reference these notes at any time.',
      ERROR: 'Please try again or come back later.',
    },
    DELETE: {
      SUCCESS: 'You’ll no longer see this note in the notes section.',
      ERROR: 'The note didn’t delete at this time. Please try again later.',
    },
  },
};

const NoteActionFeedbackModel: React.FC<NoteActionFeedbackModelProps> = ({
  onClose,
}) => {
  const dispatch = useDispatch();
  const { success, message } = useSelector(
    (state: RootState) => state.devices.noteActionStatus
  );

  const isDeleteAction =
    message?.includes('Deletion') || message?.includes('deleted');

  const getSecondaryMessage = () => {
    const actionType = isDeleteAction ? 'DELETE' : 'UPDATE';
    const status = success ? 'SUCCESS' : 'ERROR';
    return NOTE_MESSAGES.SECONDARY_MESSAGE[actionType][status];
  };

  const renderIcon = () => {
    if (isDeleteAction) {
      return success ? <DeleteSuccessIcon /> : <DeleteFailedIcon />;
    }
    return success ? <UpdateSuccessIcon /> : <UpdateFailedIcon />;
  };

  const handleClose = () => {
    dispatch(clearNoteActionStatus());
    onClose();
  };

  return (
    <NewModal
      title="Note"
      showCloseX
      onClose={handleClose}
      maxWidth="sm"
      actions={{
        primaryAction: {
          text: success ? 'OK' : 'Try Again',
          onClick: handleClose,
        },
      }}
    >
      <Box p={2} display="flex" flexDirection="column" alignItems="center">
        {renderIcon()}
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '0.36px',
          }}
        >
          {message}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.2px',
            color: '#2A2A30',
          }}
        >
          {getSecondaryMessage()}
        </Typography>
      </Box>
    </NewModal>
  );
};

export default NoteActionFeedbackModel;
