import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Tab, Tabs } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import HubDetailsTab from './tabs/HubDetailsTab';
import HubHistoryTab from './tabs/HubHistoryTab';
import SeamDetectionImagesTab from './tabs/SeamDetectionImagesTab';
import ActionsTab from './tabs/ActionsTab';
import HubNotesTab from './tabs/HubNotesTab';
import {
  faCircleExclamation,
  faTriangleExclamation,
  faCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import StatusChip from '../../Shared/StatusChip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { fetchDeviceById } from '../../../store/devices/actionCreators';
import LoadingSpinner from '../../Shared/LoadingSpinner';
import { Status } from '../../../shared/constants';
import { capitalizeFirstLetter } from '../../../shared/utils';
import { Device } from '../../../store/devices/deviceTypes';

const StatusConfig = {
  [Status.Online]: {
    icon: faCircleCheck,
    color: '#2D9D78',
    text: Status.Online,
    backgroundColor: 'var(--Semantics-S2---Success-Bg, #ECF8F0)',
  },
  [Status.Offline]: {
    icon: faTriangleExclamation,
    color: '#E94242',
    text: Status.Offline,
    backgroundColor: 'var(--Semantics-S11---Error-Bg, #FCF0EE)',
  },
  [Status.Unassigned]: {
    icon: faCircleExclamation,
    color: '#FCCD32',
    text: Status.Unassigned,
    backgroundColor: '#FFFAEB',
  },
} as const;

const getStatusConfig = (status: string | undefined, isUnassigned: boolean) => {
  if (isUnassigned) {
    return StatusConfig[Status.Unassigned];
  }
  if (status) {
    const normalizedStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return (
      StatusConfig[normalizedStatus as keyof typeof StatusConfig] ||
      StatusConfig[Status.Offline]
    );
  }
  // Default to offline if no status
  return StatusConfig[Status.Offline];
};

const tabStyles = {
  indicator: {
    backgroundColor: '#865E9C',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '14px',
    color: '#585863',
    lineHeight: '20px',
    letterSpacing: '0.24px',
  },
  selected: {
    color: '#2A2A30 !important',
  },
};

interface TabComponentProps {
  selectedDevice: Device | null;
}

interface TabConfig {
  label: string;
  component: React.ComponentType<TabComponentProps>;
}

const tabConfig: TabConfig[] = [
  { label: 'Hub details', component: HubDetailsTab },
  { label: 'Hub history', component: HubHistoryTab },
  { label: 'Seam detection images', component: SeamDetectionImagesTab },
  { label: 'Actions', component: ActionsTab },
  { label: 'Notes', component: HubNotesTab },
];

const CareHubDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { selectedDevice, isLoading } = useSelector(
    (state: RootState) => state.devices
  );

  const isUnassigned = selectedDevice?.customer_id === null;
  const statusConfig = getStatusConfig(
    selectedDevice?.device_status,
    isUnassigned
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchDeviceById(Number(id)));
    }
  }, [id, dispatch]);

  if (isLoading || !selectedDevice) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          history.goBack();
        }}
        sx={{
          border: '1px solid #EFEFF1',
          borderRadius: '10px',
          width: '60px',
          height: '40px',
          '&:hover': {
            border: '1px solid #EFEFF1',
          },
        }}
      >
        Back
      </Button>
      <Box py={2} display="flex" flexDirection="column" gap="8px">
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '30px',
              letterSpacing: '0.36px',
              color: isUnassigned ? '#000000' : '#865E9C',
            }}
          >
            {/* TODO : hyperlink the name if the profile view and url is available to develop */}
            {isUnassigned
              ? 'Unassigned'
              : selectedDevice?.first_name || selectedDevice?.last_name
              ? `${selectedDevice?.first_name} ${selectedDevice?.last_name}`
              : 'N/A'}
          </Typography>
          <StatusChip
            icon={statusConfig.icon}
            iconColor={statusConfig.color}
            labelText={statusConfig.text}
            conditionalValue={true}
            backgroundColor={statusConfig.backgroundColor}
          />
        </Box>
        {!isUnassigned && (
          <>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#585863',
              }}
            >
              {capitalizeFirstLetter(selectedDevice?.gender)} · Age{' '}
              {selectedDevice?.age || 'N/A'}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '0.2px',
                color: '#865E9C',
              }}
            >
              {selectedDevice?.email || 'N/A'} ·{' '}
              {selectedDevice?.phone || 'N/A'}
            </Typography>
          </>
        )}
        {isUnassigned && (
          <Button
            variant="contained"
            color="secondary"
            sx={{
              marginTop: 2,
              width: '132px',
              height: '40px',
            }}
          >
            Assign Hub
          </Button>
        )}
      </Box>

      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          '& .MuiTabs-indicator': tabStyles.indicator,
          '& .MuiTab-root': tabStyles.tab,
          '& .Mui-selected': tabStyles.selected,
        }}
      >
        {tabConfig.map(({ label }) => (
          <Tab key={label} label={label} />
        ))}
      </Tabs>
      <Box sx={{ borderBottom: 1, borderColor: '#EFEFF1' }} />
      <Box mt={2}>
        {tabConfig[selectedTab]?.component &&
          React.createElement(tabConfig[selectedTab].component, {
            selectedDevice: selectedTab === 0 ? selectedDevice || null : null,
          })}
      </Box>
    </Box>
  );
};

export default CareHubDetails;
