import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import MaterialTable, { Column, Options } from '@material-table/core';
import {
  Typography,
  FormControl,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  SelectChangeEvent,
} from '@mui/material';
import MuiInput from '@mui/material/Input';
import { TableOptions } from '../../../shared/tableUtils';
import StyledSelect from '../../Shared/StyledSelect';
import { ShadowConfig } from '../../../store/devices/deviceTypes';
import { updateDeviceShadow } from '../../../store/devices/actionCreators';

enum ShadowConfigType {
  TEXT = 'text',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
}

interface ShadowConfigContext {
  configPrettifiedName: string;
  readOnly: boolean;
  type: ShadowConfigType;
  validValues?: any[];
  step?: number;
  min?: number;
  max?: number;
}

const shadowFieldMapping: { [key: string]: ShadowConfigContext } = {
  dispenser_blade_bottom_value: {
    configPrettifiedName: 'Dispenser Blade Bottom Value',
    readOnly: false,
    type: ShadowConfigType.INTEGER,
  },
  application_log_level: {
    configPrettifiedName: 'Application Log Level',
    readOnly: false,
    type: ShadowConfigType.TEXT,
    validValues: ['DEBUG', 'INFO', 'WARNING', 'CRITICAL', 'FATAL'],
  },
  dispenser_enable_nick: {
    configPrettifiedName: 'Dispenser Enable Nick',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  webrtc_audio_echo_cancellation: {
    configPrettifiedName: 'WebRTC Audio Echo Cancellation',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  webrtc_audio_noise_suppression: {
    configPrettifiedName: 'WebRTC Audio Noise Suppression',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  webrtc_audio_use_fec: {
    configPrettifiedName: 'WebRTC Audio Use FEC',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  webrtc_disable_decoder_during_dispense: {
    configPrettifiedName: 'WebRTC Disable Decoder During Dispense',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  webrtc_disable_encoder_during_dispense: {
    configPrettifiedName: 'WebRTC Disable Encoder During Dispense',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  med_event_window_sec: {
    configPrettifiedName: 'Medication Event Active Time Window',
    readOnly: false,
    type: ShadowConfigType.INTEGER,
    step: 900,
    min: 900,
    max: 86400,
  },
  dispenser_debug_bounding_box_enable: {
    configPrettifiedName: 'Dispenser Enable Bounding Box Debugging',
    readOnly: false,
    type: ShadowConfigType.INTEGER,
    validValues: [0, 1],
  },
  reset_medication_schedule: {
    configPrettifiedName: 'Reset Medication Schedule',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  enable_system_test_mode: {
    configPrettifiedName: 'Enable System Test Mode',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  seam_position_confidence: {
    configPrettifiedName: 'Seam Position Baseline Confidence',
    readOnly: false,
    type: ShadowConfigType.INTEGER,
    min: 0,
    max: 100,
  },
  retrieve_careteam_update: {
    configPrettifiedName: 'Retrieve Careteam Update',
    readOnly: true,
    type: ShadowConfigType.BOOLEAN,
  },
  demo_mode: {
    configPrettifiedName: 'Demo Mode Enabled',
    readOnly: true,
    type: ShadowConfigType.BOOLEAN,
  },
  webrtc_stats_collection: {
    configPrettifiedName: 'Enable WebRTC Stat Collection',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  sentry_sample_rate: {
    configPrettifiedName: 'Sentry: Set Sample Rate',
    readOnly: false,
    type: ShadowConfigType.INTEGER,
    min: 0,
    max: 100,
  },
  is_sentry_control_group: {
    configPrettifiedName: 'Sentry: In Control Group',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  dispense_with_door_open: {
    configPrettifiedName: 'Enable Dispense with Door Open',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
  demo_mode_allowed: {
    configPrettifiedName: 'Enable demo mode option on hub',
    readOnly: false,
    type: ShadowConfigType.BOOLEAN,
  },
};

const renderShadowConfigs = (
  returnValue: any,
  rowData: ShadowConfig & ShadowConfigContext,
  handleConfigValueUpdate: any,
  disabled: boolean = false
) => {
  if (rowData.validValues) {
    return (
      <FormControl required variant="outlined" sx={{ minWidth: '200px' }}>
        <StyledSelect
          value={returnValue}
          onChange={(event: SelectChangeEvent<any>) => {
            handleConfigValueUpdate(rowData.configName, event.target.value);
          }}
          sx={{ width: '200px', textAlign: 'left' }}
          disabled={disabled || rowData.readOnly}
        >
          {[undefined].concat(rowData.validValues).map((value, idx) => (
            <MenuItem key={idx} value={value}>
              {value}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    );
  } else if (rowData.type === ShadowConfigType.BOOLEAN) {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={returnValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleConfigValueUpdate(
                  rowData.configName,
                  event.target.checked
                );
              }}
              disabled={disabled || rowData.readOnly}
            />
          }
          label=""
        />
      </FormGroup>
    );
  } else if (rowData.type === ShadowConfigType.INTEGER) {
    return (
      <MuiInput
        value={returnValue}
        inputProps={{
          step: rowData.step ?? 1,
          min: rowData.min ?? 0,
          max: rowData.max ?? Number.MAX_SAFE_INTEGER,
          type: 'number',
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const maxInt = rowData.max ? rowData.max : Number.MAX_SAFE_INTEGER;
          let enteredInt = parseInt(event.target.value);
          if (enteredInt > maxInt) enteredInt = maxInt;
          handleConfigValueUpdate(rowData.configName, enteredInt);
        }}
        disabled={disabled || rowData.readOnly}
      />
    );
  }
  return (
    <>{`${
      returnValue === undefined || returnValue === null ? '' : returnValue
    }`}</>
  );
};

interface ShadowState {
  [key: string]: any;
}

interface DeviceShadowsProps {
  configs: ShadowConfig[] | null;
  deviceId: number;
}

const DeviceShadowsConfig = (props: DeviceShadowsProps) => {
  const dispatch = useDispatch();
  const { configs, deviceId } = props;

  const [updatedConfigDesiredValues, setUpdatedConfigDesiredValues] = useState(
    {} as ShadowState
  );
  const [controlDesiredState, setControlDesiredState] = useState(
    {} as ShadowState
  );

  const fullDatailedConfigs: (ShadowConfig & ShadowConfigContext)[] = useMemo(
    () =>
      configs
        ? configs.map((config) => {
            const shadowConfigContext = shadowFieldMapping[config.configName];
            if (shadowConfigContext) {
              return {
                ...config,
                ...shadowConfigContext,
                desiredValue:
                  typeof config.desiredValue === 'string'
                    ? config.desiredValue.toUpperCase()
                    : config.desiredValue,
                reportedValue:
                  typeof config.reportedValue === 'string'
                    ? config.reportedValue.toUpperCase()
                    : config.reportedValue,
              } as ShadowConfig & ShadowConfigContext;
            }
            return config as ShadowConfig & ShadowConfigContext;
          })
        : [],
    [configs]
  );

  const allValidFullDatailedConfigs: (ShadowConfig & ShadowConfigContext)[] =
    useMemo(() => {
      const shadowFieldMappingWithData = {
        ...shadowFieldMapping,
        ...fullDatailedConfigs?.reduce(
          (
            acc: { [key: string]: ShadowConfig & ShadowConfigContext },
            curr: ShadowConfig & ShadowConfigContext
          ) => {
            acc[curr.configName] = curr;
            return acc;
          },
          {}
        ),
      };
      return Object.entries(shadowFieldMappingWithData).map(
        ([key, value]) =>
          ({
            ...value,
            configName: key,
          } as ShadowConfig & ShadowConfigContext)
      );
    }, [fullDatailedConfigs]);

  useEffect(() => {
    return () => {
      const initialDesiredState: ShadowState = {};
      for (const config of allValidFullDatailedConfigs) {
        initialDesiredState[config.configName] = config.desiredValue;
      }
      setControlDesiredState(initialDesiredState);
      setUpdatedConfigDesiredValues(initialDesiredState);
    };
  }, [allValidFullDatailedConfigs]);

  const handleConfigValueUpdate = (configName: string, newValue: any) => {
    setUpdatedConfigDesiredValues({
      ...updatedConfigDesiredValues,
      [configName]: newValue,
    });
  };

  const columns: Column<ShadowConfig & ShadowConfigContext>[] = [
    {
      title: 'Configuration Name',
      defaultSort: 'asc',
      field: 'configPrettifiedName',
      customSort: (a, b) =>
        a.configPrettifiedName.localeCompare(b.configPrettifiedName),
      render: (rowData) => {
        return <>{rowData.configPrettifiedName || rowData.configName}</>;
      },
    },
    {
      title: 'Desired',
      render: (rowData) => {
        return renderShadowConfigs(
          updatedConfigDesiredValues[rowData.configName],
          rowData,
          handleConfigValueUpdate,
          configs && configs.length ? false : true
        );
      },
    },
    {
      title: 'Reported',
      render: (rowData) => {
        return renderShadowConfigs(
          rowData.reportedValue,
          rowData,
          handleConfigValueUpdate,
          true
        );
      },
    },
  ];

  return (
    <>
      <Typography variant={'h1'}>
        Device Shadow
        {
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              const newShadowState: ShadowState = {};
              Object.keys(updatedConfigDesiredValues).forEach((key) => {
                if (
                  controlDesiredState[key] !== updatedConfigDesiredValues[key]
                ) {
                  newShadowState[key] = updatedConfigDesiredValues[key];
                }
              });
              dispatch(updateDeviceShadow(deviceId, newShadowState));
            }}
            sx={{ marginLeft: '25px' }}
            disabled={isEqual(controlDesiredState, updatedConfigDesiredValues)}
          >
            Update
          </Button>
        }
      </Typography>
      <MaterialTable
        columns={columns}
        data={allValidFullDatailedConfigs}
        options={{
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          ...(TableOptions as Partial<
            Options<ShadowConfig & ShadowConfigContext>
          >),
        }}
      />
    </>
  );
};

export default DeviceShadowsConfig;
