import React, { ReactElement } from 'react';
import { Chip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightBlack } from '../../mainTheme';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

interface StatusChipProps {
  icon: any;
  iconColor: string;
  labelText: string;
  conditionalValue: boolean;
  backgroundColor?: string;
}

const StatusChip: React.FC<StatusChipProps> = ({
  icon,
  iconColor,
  labelText,
  conditionalValue,
  backgroundColor,
}) => {
  const IconComponent = React.isValidElement(icon) ? (
    React.cloneElement(icon as ReactElement, {
      style: { fill: iconColor },
    })
  ) : (
    <FontAwesomeIcon
      data-testid="status-chip-icon"
      icon={icon as IconDefinition}
      style={{ color: iconColor }}
    />
  );

  return (
    <Chip
      data-testid="status-chip"
      icon={IconComponent}
      label={
        <Typography
          data-testid="status-chip-label"
          sx={{ color: lightBlack }}
          variant="caption"
        >
          {labelText}
        </Typography>
      }
      sx={{
        backgroundColor: conditionalValue
          ? backgroundColor ?? 'var(--Semantics-S2---Success-Bg, #ECF8F0)'
          : 'var(--Semantics-S11---Error-Bg, #FCF0EE)',
        color: 'white',
        padding: '0px 0px 0px 8px',
        '& .MuiChip-icon': {
          color: 'white',
        },
      }}
    />
  );
};

export default StatusChip;
