import React, { useState, useRef, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { TextInputFormField } from '../../../Shared/TextInputFormField';
import CCDropdownForm from '../../../Shared/CCDropdownForm';
import CustomerCareDTable from '../../../Shared/CustomerCareDTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import {
  addDeviceNote,
  deleteDeviceNote,
  fetchDeviceNotes,
  updateDeviceNote,
} from '../../../../store/devices/actionCreators';
import UpdateNoteForm from './components/UpdateNoteForm';
import NoteActionFeedbackModel from './components/NoteActionFeedbackModel';
import DeleteNoteConfirmation from './components/DeleteNoteConfirmation';
import StatusChip from '../../../Shared/StatusChip';
import HubIcon from '../../../../images/icons/HubIcon';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { parseDateToLocal } from '../../../../shared/utils';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../Shared/LoadingSpinner';

const validationSchema = yup.object({
  note: yup.string().required('Note content is required'),
  category: yup.string().required('Note type is required'),
});

// Note type options
const noteTypeOptions = [
  { value: 'hub', label: 'Hub' },
  { value: 'patient', label: 'Patient' },
];

interface RouteParams {
  id: string;
}

const HubNotesTab = () => {
  const { id } = useParams<RouteParams>();
  const { deviceNotes, isNotesLoading } = useSelector(
    (state: RootState) => state.devices
  );
  const { show: showFeedback } = useSelector(
    (state: RootState) => state.devices.noteActionStatus
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchDeviceNotes(Number(id)));
    }
  }, [dispatch, id]);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>(null);
  const formikRef = useRef<any>(null);

  const handleSubmit = (values: { note: string; category: string }) => {
    dispatch(addDeviceNote(Number(id), values.note, values.category));
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  // handle Edit confirmation model
  const handleEdit = (noteId: number) => {
    const noteToEdit = deviceNotes?.find((note) => note.note_id === noteId);
    if (noteToEdit) {
      setSelectedNote(noteToEdit);
      setEditModalOpen(true);
    }
  };

  const handleEditSubmit = (values: { note: string; category: string }) => {
    if (selectedNote) {
      dispatch(
        updateDeviceNote(
          Number(id),
          selectedNote.note_id,
          values.note,
          values.category
        )
      );
      setEditModalOpen(false);
      setSelectedNote(null);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedNote(null);
  };

  // Handle note deletion confirmation
  const handleDeleteConfirm = (noteId: any) => {
    setNoteToDelete(noteId);
    setDeleteConfirmOpen(true);
  };

  // Handle  note deletion
  const handleDelete = () => {
    if (noteToDelete) {
      dispatch(deleteDeviceNote(Number(id), noteToDelete));
      setDeleteConfirmOpen(false);
      setNoteToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setNoteToDelete(null);
  };

  const ActionCell = ({ params }: { params: { id: number } }) => (
    <Box
      display="flex"
      justifyContent="end"
      borderRadius="8px"
      border="1px solid var(--Outline-O5---Separator, #D4D4D9)"
    >
      <Tooltip title="Edit">
        <IconButton onClick={() => handleEdit(params.id)} size="small">
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ marginRight: 8 }}
            size="sm"
          />
        </IconButton>
      </Tooltip>
      <Box
        width="1px"
        bgcolor="var(--Outline-O5---Separator, #D4D4D9)"
        marginY={1}
      />
      <Tooltip title="Delete">
        <IconButton onClick={() => handleDeleteConfirm(params.id)} size="small">
          <FontAwesomeIcon
            icon={faTrashCan}
            size="sm"
            style={{ marginLeft: 8 }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const tableRows =
    deviceNotes?.map((note) => ({
      id: note.note_id,
      content: note.note,
      type: note.category,
      date: note.created_at
        ? parseDateToLocal(note.created_at, 'yyyy-MM-dd HH:mm:ss')
        : 'N/A',
    })) || [];

  const columns = [
    {
      field: 'content',
      headerName: 'Note',
      flex: 3,
      sortable: true,
    },
    {
      field: 'type',
      headerName: 'Note type',
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        const noteType = params.value;
        const icon = noteType === 'hub' ? <HubIcon /> : faUser;

        return (
          <Box display="flex" alignItems="center">
            <StatusChip
              icon={icon}
              iconColor="#FA881E"
              labelText={noteType === 'hub' ? 'Hub' : 'Patient'}
              conditionalValue={true}
              backgroundColor="#FFF4EB"
            />
          </Box>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date added',
      flex: 1,
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params: any) => <ActionCell params={{ id: params.id }} />,
    },
  ];

  if (isNotesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box pt={2}>
      {/* Notes Form */}
      <Formik
        innerRef={formikRef}
        initialValues={{ note: '', category: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form>
            <Box>
              <Field
                label=""
                name="note"
                placeholder="Type here to add a new note..."
                component={TextInputFormField}
                multiline
                rows={2}
                variant="standard"
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '8px',
                    border: '1px solid #D4D4D9',
                    '& textarea': {
                      '&::placeholder': {
                        color: '#9999A4',
                      },
                    },
                    '&::before': {
                      display: 'none',
                    },
                    '&::after': {
                      display: 'none',
                    },
                  },
                  '& .MuiInputBase-input': {
                    padding: '12px',
                    color: '#2A2A30',
                  },
                }}
              />

              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
              >
                <Field
                  label="Note Type"
                  name="category"
                  component={CCDropdownForm}
                  options={noteTypeOptions}
                  sx={{ width: '50%' }}
                />

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={
                    !formik.values.note ||
                    !formik.values.category ||
                    formik.isSubmitting
                  }
                >
                  Save note
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      {tableRows.length ? (
        <CustomerCareDTable
          rows={tableRows}
          columns={columns}
          pageSize={5}
          defaultSortField="date"
          defaultSortDirection="desc"
          hideViewAllButton
          loading={isNotesLoading}
        />
      ) : null}

      {editModalOpen && selectedNote && (
        <UpdateNoteForm
          initialValues={{
            note: selectedNote.note,
            category: selectedNote.category,
          }}
          onSubmit={handleEditSubmit}
          noteTypeOptions={noteTypeOptions}
          onClose={handleCloseEditModal}
        />
      )}

      {/* API response based Popup model */}
      {showFeedback && (
        <NoteActionFeedbackModel
          onClose={() => {
            handleCloseEditModal();
            dispatch(fetchDeviceNotes(Number(id)));
          }}
        />
      )}

      {/* Delete Confirmation Dialog */}
      {deleteConfirmOpen && (
        <DeleteNoteConfirmation
          onClose={handleCancelDelete}
          onDelete={handleDelete}
        />
      )}
    </Box>
  );
};

export default HubNotesTab;
